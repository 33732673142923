module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W6M3F67EQP"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Boctoz","short_name":"Boctoz","description":"Boctoz（ボクトツ）は、知的なあなたに向けて、素朴な教訓のある文章を発信しています。","start_url":"/","lang":"ja","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon-rounded.png","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cf52ff8edd579d61994aa0d31e14cf2f"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Boctoz","description":"Boctoz（ボクトツ）は、知的なあなたに向けて、素朴な教訓のある文章を発信しています。","language":"ja","openGraph":{"title":"Boctoz","description":"Boctoz（ボクトツ）は、知的なあなたに向けて、素朴な教訓のある文章を発信しています。","images":[{"url":"https://images.ctfassets.net/96mljui2ibo8/5wmtbyG3oakEGiOZpk2Pxh/3f45fc1f40c90d32c203ee94b9c1e416/og.png","width":1200,"height":630,"alt":"Boctoz"}],"type":"website","locale":"ja_JP","url":"https://boctoz.com","site_name":"Boctoz"},"twitter":{"site":"@boctoz","handle":"@boctoz","cardType":"summary_large_image"},"facebook":{"appId":"your-app-id"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"gold","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
